<template>
  <div>

    <div
      v-if="infosSalon !== null"
      class="has-text-centered"
      style="margin-bottom: 9px;"
    >
      <h1 class="title" style="font-family:cabin_sketch_bold;">
        {{ infosSalon.nom_salon }}
        <DrapeauFr v-if="infosSalon.langue === 'fr'" class="drapeau"/>
        <DrapeauUsaUk v-if="infosSalon.langue === 'en'" class="drapeau"/>
      </h1>
    </div>

    <div
      class="has-text-centered box"
      :class="{
        'frosted-background-static': $isSafari,
        'frosted-background': !$isSafari,
      }"
      style="font-family:cabin_sketch_regular; font-size: 30px"
    >
      <div v-if="waitPassword">
        <label>
          {{ $t('sketchy.global.password') }}
        </label>
        <br/>
        <input
          type="text"
          class="input"
          style="width: 200px; margin-top: 20px"
          @keyup.enter="sendPassword"
          v-model="form.password"
          :disabled="envoiPassEnCours"
          :maxlength="$sketchy_pass_max"
        />
        <button
          style="margin-top: 20px"
          @click="sendPassword"
          class="button"
          :disabled="envoiPassEnCours"
          :class="{'is-loading': envoiPassEnCours}"
        >{{ $t('sketchy.global.connexion') }}
        </button>

        <div v-if="passInvalid" style="font-size: 20px; margin-top: 20px">
          <span class="tag is-danger is-large">{{ $t('sketchy.page_jeu.etat_0.invalid_password') }}</span>
        </div>
      </div>
      <div v-else>
        <label>
          {{ $t('sketchy.global.choix_pseudo') }}
        </label>
        <br/>
        <button
          v-if="disabledInputPseudo"
          class="button is-static"
          style="width: 200px;margin-top: 20px;pointer-events: all;cursor: default;"
          @click="inputPseudoClick"
        >{{ form.nom_joueur }}</button>
        <input
          v-else
          @keyup.enter="connect"
          ref="choixPseudo"
          type="text"
          class="input"
          style="width: 200px;margin-top: 20px;"
          v-model="form.nom_joueur"
          :maxlength="$sketchy_pseudo_max"
        />
        <button
          style="margin-top: 20px"
          class="button"
          :class="{'is-loading': connexionEnCours}"
          @click="connect"
          :disabled="connexionEnCours"
        >{{ $t('sketchy.global.connexion') }}
        </button>
        <div v-if="userExist" style="margin-top: 10px">
          <span class="tag is-danger is-medium">{{ $t('sketchy.page_jeu.etat_0.pseudo_pris') }}</span>
        </div>
        <div v-if="nomJoueurTropGrand">
          <span class="tag is-danger is-medium">{{
              $t('sketchy.page_jeu.etat_0.pseudo_long_$d', [$sketchy_pseudo_max])
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  border: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 10px;
}

.drapeau {
  height: 20px;
  width: 30px;

  margin-left: 3px;
}
</style>
<script>
export default {
  name: 'Etat_NonConnecte',

  data() {
    return {
      envoiPassEnCours: false,

      infosSalon: null,

      form: {
        nom_joueur: '',
        password: '',
      },

      nom_joueur_default: '',

      disabledInputPseudo: false,
      disabledInputPseudoCount: 0,
    };
  },

  created() {
    this.$axios.get(this.$url_serveur + '/salon/' + this.salon_id + '?existe')
      .then((resp) => {
        console.info('RES CHECK SALON EXISTE =>', resp);
        this.infosSalon = resp.data;

        if (this.$session.data._last_salon && this.$session.data._last_salon === this.$route.params.salon && this.$session._last_pseudo) {
          if (this.infosSalon.etat === 'e1' || this.infosSalon.etat === 'e2' || this.infosSalon.etat === 'e3') {
            this.disabledInputPseudo = true;
          }
          this.form.nom_joueur = this.$session._last_pseudo;
        }
      })
      .catch((err) => {
        if (err.response.status === 410 /*GONE*/) {
          // Pour afficher sur la page d'accueil que le salon existe plus
          window._sketchy_err_salon_existe_plus = true;
        }
        this.$router.push({
          name: 'Accueil',
        });
      });
  },

  mounted() {
    this.$nextTick(() => {
      if ('choixPseudo' in this.$refs && this.$refs.choixPseudo !== undefined && this.$refs.choixPseudo.focus)
        this.$refs.choixPseudo.focus();
    });
  },

  computed: {
    nomJoueurTropGrand() {
      return this.form.nom_joueur.length > this.$sketchy_pseudo_max;
    },
  },

  methods: {
    connect() {
      this.$emit('interact');

      if (this.nomJoueurTropGrand) return;
      if (this.connexionEnCours) return;

      if (this.$session.data._last_salon && this.$session.data._last_salon === this.$route.params.salon && this.$session._last_pseudo) {
        if (this.$session._last_pseudo === this.form.nom_joueur) {
          console.info('Rejoin au lieu de connexion normale');
          this.$emit('rejoin');
          return;
        }
      }

      this.$emit('connect', this.form.nom_joueur);
    },

    sendPassword() {
      this.$emit('interact');

      if (this.envoiPassEnCours) return;
      this.envoiPassEnCours = true;
      this.$emit('sendMsg', 'wait_password', this.form.password);
    },

    inputPseudoClick() {
      if (this.disabledInputPseudo && this.disabledInputPseudoCount < 3) {
        this.disabledInputPseudoCount += 1;
        if (this.disabledInputPseudoCount >= 3) {
          this.disabledInputPseudo = false;
        }
      }
    },
  },

  watch: {
    'passInvalid': function (v) {
      if (v) {
        this.envoiPassEnCours = false;
      }
    },
    'userExist': function (v) {
      if (v) {
        this.envoiPassEnCours = false;
      }
    },

    '$locale': {
      handler: function () {
        if (this.nom_joueur_default === this.form.nom_joueur) {
          this.form.nom_joueur = this.$locale === 'fr' ?
            this.$jeans[this.randBetween(0, this.$jeans.length - 1)] :
            this.$jeans_en[this.randBetween(0, this.$jeans_en.length - 1)];
          this.nom_joueur_default = this.form.nom_joueur;
        }
      },
      immediate: true,
    }
  },

  components: {
    DrapeauFr: () => import('@/resources/images/Icone_pays_fr'),
    DrapeauUsaUk: () => import('@/resources/images/Icone_pays_usa_uk'),
  },

  props: [
    'waitPassword',
    'passInvalid',
    'userExist',
    'connexionEnCours',
    'salon_id',
  ],
}
</script>
